/* Provide sufficient contrast against white background */
@import "~font-awesome/css/font-awesome.css";
body {
    font-family: 'Inter', sans-serif !important;
}

a {
    color: blue;
    font-family: 'Inter', sans-serif !important;
}

b {
    color: #1E4E59;
    font-family: 'Inter', sans-serif !important;
}

p {
    font-family: 'Inter', sans-serif !important;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

.recaptcha-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Additional styling if needed */
}

.nav-item{
    cursor:pointer !important;
}

#root {
    background-color: #eee;
}

code {
  color: #E01A76;
}

input[type=text] {
    background-color: #e8e6e6 !important;
    border-color: #3FA693 !important;
}



.menu-background {
    background-color: #eee !important;
}

.match-container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set the container's height to 100% of the viewport height */
}

.in-match-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.in-match-container > .row {
    margin: 0; /* Remove any default margins */
    width: 80%; /* Make sure the row takes up the full width */
}

.ladder-data-container
{
    height:500px !important;
    overflow-y:scroll !important;
}


.landing-page-headers {

}

.container-box {
    background-color: #d3d3d3; /* Set the background color to a light gray */
    padding: 20px; /* Add some padding around the content */
    border: 1px solid #ddd; /* Add a border for separation */
    border-radius: 10px; /* Add rounded corners for a subtle effect */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Apply a subtle box shadow */
}

h1, h2, h3, h4, h5 {
    color: #A65F08 !important;
}


.btn-primary {
    background-color: #1E4E59 !important;
    color: #F2CA50 !important;
    border-color: #3FA693 !important;
    cursor:pointer !important;
}


.btn-secondary {
    background-color: #1E4E59 !important;
    color: #F2CA50 !important;
    border-color: #3FA693 !important;
    cursor: pointer !important;
}

.btn-custom {
    color: white;
    background-color: #1E4E59;
    color: #F2CA50 !important;
    cursor: pointer !important;
}

.btn-danger {
    background-color: #A65F08 !important;
    color: white !important;
    cursor: pointer !important;
}

    .btn-custom:hover {
        color: white;
        background-color: #3FA693;
    }

.card {
    border-color: #3FA693 !important;
}



.odd-row {
    padding: 15px;
    background-color: #1E4E59;
    color: #F2CA50;
    margin-bottom:10px;
}

.even-row {
    padding: 15px;
    background-color: #3FA693;
    color: white;
    margin-bottom: 10px;
}

.card
{
    background-color:#eee !important;
}


.active {
    background-color: #A65F08 !important;
    color: white !important;
   
}

.tab-pane-active
{
    color:black !important;
    background-color: #eee !important;
}
.stat-card {
    border: 1px solid #3FA693 !important;
    padding:15px;
    margin:5px;
}

.inactive {
    color: #1E4E59 !important;
    border: 1px solid #1E4E59 !important
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This will make it take up the full viewport height */
    text-align: center;
}

.centered-form {
    max-width: 100%; /* Adjust this to your preferred width */
    width: 100%;
}

.centered-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* semi-transparent overlay background */
}

    .centered-modal .modal {
        max-width: 80%; /* Adjust this value as needed for your design */
        width: auto;
        background-color: #fff; /* Adjust the background color as needed */
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional box shadow for the modal */
        z-index: 1000; /* Adjust the z-index as needed to ensure it's on top of other content */
        position: relative;
    }
/* Custom media query to control the layout on smaller screens, including tablets */
@media (max-width: 991px) {
    [role="menu"] {
        width: 100%;
    }


    .dropdown-toggle
    {
        width:100% !important;
    }
    .container .row {
        display: flex;
        flex-wrap: wrap;
    }

        .container .row > div {
            flex-basis: 100%;  
        }

        .container .row .col-12 {
            flex-basis: 50%; /* Revert the right column to 50% on smaller screens */
        }

    .mobile-custom-col {
        flex-basis: 0% !important;
       
    }
    .mobile-custom-row {
        display:block !important;
    }


}

@media (max-width: 1200px) {
    .mobile-custom
    {
        width:100% !important;
    }
} 


